<template>
    <div>
        <el-card class="mt-2 ml-4 mr-4 bg-white border-white">

            <div style="width: 100%; margin: auto; " class="font-semibold text-lg">
                <span>{{className}}</span>-<span>{{classK}}</span>
            </div>
            <!-- 查询框 -->
            <el-form :model="searchData" class="demo-form-inline mt-4 pl-6 pr-6" :label-width="labelWidth">
                <el-row class="flex">
                    <el-input class="mr-2" v-model="searchData.name" size="small" style="width: 11rem;"
                        placeholder="学生姓名">
                    </el-input>
					<el-date-picker v-model="searchData.createTime" type="date" size="small" format="yyyy-MM-dd"
					    value-format="yyyy-MM-dd" placeholder="选择日期">
					</el-date-picker>
                    <el-button type="primary" plain size="small" style="background-color: #706fa8c3;color: white;margin-left: 1rem;"
                        icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="primary" size="small" style="background-color: white;color: black;"
                        icon="el-icon-refresh" plain @click="reset">重置</el-button>
                   
                </el-row>
            </el-form>
            <!-- 表格 -->
            <el-tabs v-model="activeName" @tab-click="handleClick" class="mt-4 ml-4">
                <el-tab-pane :label="'全部 '+top.all" name="first">
                    <tableClass :tableData="tableData"></tableClass>
                </el-tab-pane>
                <el-tab-pane :label="'已到 '+top.yes" name="second">
                    <tableClass :tableData="tableData"></tableClass>
                </el-tab-pane>
                <el-tab-pane :label="'缺勤 '+top.no" name="three">
                    <tableClass :tableData="tableData"></tableClass>
                </el-tab-pane>
            </el-tabs>
            <!-- <div class="flex justify-end text-xs mt-4">*该表格的已到状态会在当日的24:00后自动更新</div> -->
            <!-- 分页 -->
            <div class="flex  bg-white ml-4 mt-6 pt-4 pb-4">
                <div></div>
                <Pagination :currentPage="currentPage" :total="total" :pageSize="pageSize" @pageChange="pageChange" />
            </div>
        </el-card>


    </div>
</template>

<script>
    import Pagination from "../../components/Pagination/pagination";
    import tableClass from "./components/tableClass.vue"
    export default {
        components: { Pagination, tableClass },
        data() {
            return {
                form: {
                    imgUrl: "", //图片字段
                    platName: "", //自定义字段
                },
                activeName: 'first',
                labelWidth: "100px",
                searchData: {
                    name: '',
                    createTime: ''
                },
                tableData: [],
                top: {
                    all: '',
                    no: '',
                    yes: ''
                },
                // 对话框
                isAdd: false, //区分添加和编辑
                dialog: false,
                // 分页
                total: 0,
                currentPage: 1,
                nursery_id: '',
                pageSize: 10,
                className: '',//园区名字
                classK: '',//班级名字
                tabIndex: 0,//标签也切换
                nowTime: ''
            };
        },

        computed: {

        },

        watch: {},
        mounted() {
            this.nursery_id = window.sessionStorage.getItem("class_id")
            this.className = window.sessionStorage.getItem("className")
            this.classK = window.sessionStorage.getItem("class")
            this.nowDate()
            this.getList();  //一开始获取数据
        },
        methods: {
            nowDate() {
                //获取当前时间
                var date = new Date();
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate();
                if (month < 10) {
                    month = "0" + month;
                }
                if (day < 10) {
                    day = "0" + day;
                }
                this.nowTime = year + "-" + month + "-" + day;
            },
            handleClick(tab, event) {
                this.tabIndex = tab.index
                if (this.check.checkEmpty(this.searchData)) {
                    this.getList()
                } else {
                    this.commonData()
                }
            },
            //重置
            reset() {
                this.pageSize = 10
                this.currentPage = 1
                this.searchData = {}
                this.getList()
            },
            // 获取基本数据
            async getList() {
                const RES = await this.http.post("/admin/studentclass/all", {
                    limit: this.pageSize,
                    page: this.currentPage,
                    class_id: this.nursery_id,
                    key: this.tabIndex
                });
                if (RES.code == 200||RES.code==400) {
                    this.tableData = RES.data??0;
                    this.total = RES.total??0;
                    this.top.all = RES.all??0
                    this.top.no = RES.no??0
                    this.top.yes = RES.yes??0
					
                }
            },
            // 编辑
            handleEdit(row) {
                this.form = JSON.parse(JSON.stringify(row)); //编辑给表单赋值的时候，需要把数据深度拷贝
            },
            //分页
            pageChange(index) {
                this.currentPage = index._currentPage;
                this.pageSize = index._pageSize
                this.getList();
            },
            //查询
            search() {
                this.currentPage = 1;
                this.commonData();
            },
            // 分页&&查询通用数据 分页查询的情况比较多 ，坑比较多 ，建议和我写成一样或者自我优化
            async commonData() {
                // if (this.searchData.createTime == null) {
                //     this.searchData.createTime = new Date();
                // }
                const RES = await this.http.post("admin/studentclass/all", {
                    limit: this.pageSize,
                    page: this.currentPage,
                    name: this.searchData.name,
                    class_id: this.nursery_id,
                    time: this.searchData.createTime == '' ? this.nowTime : this.searchData.createTime,
                    key: this.tabIndex
                });

                if (RES.code == 200 || RES.code == 204) {
                    this.tableData = RES.data
                    this.total = RES.total
                    this.top.all = RES.all
                    this.top.no = RES.no
                    this.top.yes = RES.yes
                }
                if (RES.code == 400) {
                    this.$message({
                        message: '暂无数据，请重新选择查询条件',
                        type: 'warning'
                    });
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
	
	
	

	
    /deep/.el-dialog__headerbtn .el-dialog__close {
        color: black;
    }

    /deep/.el-dialog {
        height: 300px;
    }

    /deep/.el-dialog__header {
        background-color: #f8f8f8;
        padding: 10px 10px 10px;
    }

    /deep/.el-tabs__header {
        background-color: #f8f8f8;
    }

    /deep/.el-dialog__headerbtn {
        top: 10px;
    }

    /deep/.el-dialog__title {
        color: white;
        font-size: 1rem;
    }

    /deep/.el-table th {
        background-color: white !important;
    }

    /deep/.el-tabs__nav {
        background-color: #f8f8f8;
        padding: 0 10px;
    }

    /deep/.el-tabs__item.is-active {
        color: #535c8d;
    }

    /deep/.el-tabs__active-bar {
        background-color: #535c8d;
        margin-left: 10px;
    }

    /deep/.el-table td,
    .el-table th {
        text-align: center;
    }

    /deep/.el-table th {
        text-align: center;
        background: rgb(236, 235, 235);
    }

    /deep/ .avatar-uploader .el-upload {
        border: 1px dashed hsl(0, 0%, 85%);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: rgb(204, 204, 204);
    }

    /deep/.avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    /deep/ .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }

    /deep/ .avatar {
        width: 200px;
        height: 150px;
        display: block;
    }

    /deep/.el-form-item {
        margin-bottom: 5px;
    }

    /deep/.el-dialog__body {
        padding: 0px;
    }

    /deep/.el-divider--horizontal {
        margin: 0px;
    }

    
    /deep/.el-button--primary.is-plain{
            border-color: inherit;
    }
</style>