<template>
	<div>
		<el-table :data="tableData" border style="width: 100%;margin-top: -1rem;">
			<el-table-column prop="child_id" label="" min-width="5%">
			</el-table-column>
			<el-table-column prop="child_name" label="学生姓名" min-width="10%">
			</el-table-column>
			<el-table-column label="考勤状态" min-width="15%">
				<template slot-scope="scope">
					<span :style="{color:scope.row.arrive=='已到'?'black':'red'}">{{scope.row.arrive}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="teach_name" label="班主任姓名" min-width="15%">
			</el-table-column>
			<el-table-column prop="teach_phone" label="班主任联系电话" min-width="20%">
			</el-table-column>
			<el-table-column label="学生详情" min-width="10%">
				<template slot-scope="scope">
					<el-button size="small" type="text" @click="detail(scope.row),dialog=true">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 对话框 -->
		<el-dialog :visible.sync="dialog" width="700px" title="家长详情" :close-on-click-modal="false">
			<!-- 分割线 -->
			<el-divider></el-divider>
			<div class=" ml-6 mr-6 mt-6 mb-4">
				<div style="width: 100%; margin: auto; " class="font-semibold text-sm">
					<el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>孩子信息
				</div>
				<div class="ml-6 mr-6 mt-2 mb-8 flex">
					<div class="mr-10"><span>孩子名称：{{child.name}}</span></div>
					<div class="mr-10"><span>学校名称：{{child.className}}</span></div>
					<div class="mr-10"><span>班级名称：{{child.classK}}</span></div>
				</div>
				<div style="width: 100%; margin: auto; " class="font-semibold text-sm mt-8">
					<el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>家长信息
				</div>
				<div class="ml-6 mr-6 mt-2 " v-for="(item,index) in parent">
					<div><span class="mr-10" >家长名称：{{item.name}}</span><span>联系电话：{{item.phone}}</span></div>
				</div>
				<div class="mt-6"></div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			tableData: {
				type: Array
			}
		},
		data() {
			return {
				dialog: false,
				child:{
				className:'',
				classK:'',
				name:''
				},
				parent:''
			}
		},
		mounted() {
			this.className = window.sessionStorage.getItem("className")
            this.classK = window.sessionStorage.getItem("class")
		},
		methods: {
			async detail(row){
				console.log(row)
			const res = await this.http.post("/admin/studentclass/detail",{
				child_id:row.child_id
			})
			if(res.code==200){
				this.child.name=row.name
				this.parent=res.data
			}
			}
		},
	}
</script>

<style>
</style>